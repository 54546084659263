import React from 'react'
import { Link } from 'gatsby'
import classNames from 'classnames'

const twoGridLayout = [
  {
    title: 'No website? No problem.',
    description: <>Billsby Pages helps you to create your own custom landing page to start selling subscriptions using Billsby, with no need to setup anything new, or have any experience of coding or development.<br /> <br /></>,
    bottomDescription: <>Our customers told us that they'de like to be able to use Billsby even of they don't have a website. Pages is our solution to do just that.</>,
    btnText: 'Create your Billsby Page',
    link: 'https://pages.billsby.com/',
    imgSrc: require("../images/labs-img.png")
  }
]

const oneGridLayout = [
  {
    imgSrc: require("../images/labs-img.png")
  }
]

const offerList = [
  {
    title: 'Export and interpret your data',
    description: <>Billsby Invoice Insights have moved. Now you can export your invoice data straight from your Billsby Control Panel to use in Excel or a Google Spreadsheet, which you can analyze. <br /> <br /> We even automatically add handy additional context - like whether you're dealing with a first time or a recurring invoice.</>,
    btnText: 'Find out more',
    link: 'https://support.billsby.com/docs/invoices'
  },
  {
    title: 'See customer data elsewhere',
    description: <>Billsby Customer Export has moved. You'll now be able to export customer information to use in another tool - like Google Sheets, Excel or a CRM, straight from your Billsby Control Panel. <br /> <br />Export your customer data - either everything or a specific date range - so that you can use it on your other tools.</>,
    btnText: 'Find out more',
    link: 'https://support.billsby.com/docs/customers'
  },
  {
    title: 'Analyse your subscription data',
    description: <>Billsby Subscription Export has moved. You can now export your subscription data straight from your Billsby Control Panel to use in another tool - like Google Sheets, Excel or a CRM. <br /> <br />Export your subscription data - either everything or a specific data range - so that you can use it elsewhere.</>,
    btnText: 'Find out more',

    link: 'https://support.billsby.com/docs/subscriptions'
  }
]

const Offers = () => {
  return (
    <div className="section-offers">
      <div className="container">
        <div className="offers-wrapper">
          {twoGridLayout.map((list, i) => {
            return (
              <div key={i} className={classNames('two-grid-offer-box', { 'disabled': list.disabled === true })}>
                <div className="description-holder">
                  <p className="title">{list.title}</p>
                  <p className="description">{list.description}</p>
                </div>
                {list.imgSrc && (
                  <>
                    <div className="bottom-offers-wrapper">
                      <div className="bottom-content-button-wrapper">
                        <p className="bottom-description">{list.bottomDescription}</p>
                        <Link to={list.link} target="_blank" className="btn btn-orange">{list.btnText}</Link>
                      </div>

                      <img src={list.imgSrc} className="img-bottom-billsbypage" alt="Billsby page" />
                    </div>
                    <img src={require("../images/labs-bg.svg")} className="img-pattern" alt="Pattern" />
                  </>
                )}
              </div>
            )
          })}
          {oneGridLayout.map((list, i) => {
            return (
              <div key={i} className={classNames('one-grid-offer-box', { 'disabled': list.disabled === true })}>
                {list.imgSrc && (
                  <>
                    <div className="offers-wrapper">
                      <img src={list.imgSrc} className="img-billsbypage" alt="Billsby page" />
                    </div>
                  </>
                )}
              </div>
            )
          })}
        </div>
        <div className="offers-wrapper">
          {offerList.map((list, i) => {
            return (
              <div key={i} className={classNames('offer-box', { 'disabled': list.disabled === true })}>
                <div className="description-holder">
                  <p className="title">{list.title}</p>
                  <p className="description">{list.description}</p>
                </div>
                {list.imgSrc && (
                  <>
                    <img src={list.imgSrc} className="img-billsbypage" alt="Billsby page" />
                    <img src={require("../images/labs-bg.svg")} className="img-pattern" alt="Pattern" />
                  </>
                )}
                <Link to={list.link} target="_blank" className="btn btn-orange">{list.btnText}</Link>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default Offers